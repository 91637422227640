import React, {useState} from "react";
import Scrollspy from "react-scrollspy";
import {Link} from "react-router-dom";
import ReactGA from "react-ga4";

const HeaderFluid = () => {
    const [click, setClick] = useState(false);
    const handleClick = (label) => {
            ReactGA.event({
                category: "Nav",
                action: `${label} Nav Clicked`,
                label: label, // optional
                nonInteraction: false, // optional, true/false
            });
        setClick(!click);
    }
    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 71) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    window.addEventListener("scroll", changeBackground);

    return (
        <>
            {/* Header */}
            <header
                className={
                    navbar
                        ? "main-header header-fluid fixed-header"
                        : "main-header header-fluid"
                }
            >
                <nav className="container-fluid">
                    <div className=" header-transparent">
                        {/* Brand */}
                        <Link to="/">
                            <img
                                className="navbar-brand"
                                src="img/logo/floormind-logo-color.png"
                                alt="brand logo"
                            />
                        </Link>
                        {/* / */}
                        {/* Mobile Toggle */}
                        <button
                            className="navbar-toggler"
                            type="button"
                            onClick={handleClick}
                        >
                            <div className={click ? "hamburger active" : "hamburger"}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                        {/* / */}
                        {/* Top Menu */}
                        <div
                            className={
                                click
                                    ? "mobile-menu navbar-collapse justify-content-end active"
                                    : "mobile-menu navbar-collapse justify-content-end"
                            }
                        >
                            <Link to="/" className="sidebar-logo">
                                <img src="img/logo/floormind-logo-color.png" alt="brand"/>
                            </Link>
                            <Scrollspy
                                className="anchor_nav navbar-nav ml-auto"
                                items={["home", "about", "services", "work", "blog", "contact"]}
                                currentClassName="current"
                                offset={-71}
                            >
                                <li>
                                    <a className="nav-link" href="#home" onClick={() => handleClick("Home")}>
                                        <span>Home</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="nav-link" href="#about" onClick={() => handleClick("About")}>
                                        <span>About</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="nav-link"
                                        href="#engineering"
                                        onClick={() => handleClick("Engineering")}
                                    >
                                        <span>Engineering</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="nav-link" href="#photography" onClick={() => handleClick("Photography")}>
                                        <span>Photography</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="nav-link" href="https://blog.floormind.com">
                                        <span>Blog</span>
                                    </a>
                                </li>
                                <li>
                                    <a className="nav-link" href="#contact" onClick={() => handleClick("Contact")}>
                                        <span>Contact</span>
                                    </a>
                                </li>
                            </Scrollspy>
                            <button
                                className=" sidebar-bottom-closer dark-bg"
                                type="button"
                                onClick={handleClick}
                            >
                                Close Sidebar
                            </button>
                        </div>
                        {/* / */}
                    </div>
                    {/* Container */}
                </nav>
                {/* Navbar */}
            </header>
            {/* End Header */}
        </>
    );
};

export default HeaderFluid;
