import React, {useState} from "react";
import ReactGA from "react-ga4";

const About = () => {
  const [click, setClick] = useState(false)

  const handleClick = (value) => {
    ReactGA.event({
      category: "Submit",
      action: `Download submit clicked`,
      label: "CV Downloaded", // optional
      nonInteraction: false, // optional, true/false
    })
    setClick(!click)
  }

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "about"
    });

  return (
    <>
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-6 my-3">
          <div className="img-box dark-img-box">
            <img src="img/about/floormind-about-1.jpg" alt="floormind in suit" />
          </div>
        </div>

        <div className="col-lg-6 my-3">
          <div className="typo-box about-me">
            <h3>Ife Ayelabola Jr</h3>
            <h5>
              <span className="color-theme">Multi-Disciplinary Engineer</span>
            </h5>
            <p>
              I am a passionate <span className="color-theme">software engineer</span> with a diverse skill set, dedicated to building <span className="color-theme">scalable enterprise applications</span>.
              My expertise covers the entire <span className="color-theme">software development lifecycle</span>, from <span className="color-theme">architectural design and application development</span> to <span className="color-theme">final deployment</span>, always selecting the most suitable technologies for each project.
              With a strong background in <span className="color-theme">microservices architecture</span>, I develop robust solutions using <span className="color-theme">C#, Java, Python, Go and JavaScript with frameworks like React,</span>. I specialise in <span className="color-theme">containerisation</span> with <span className="color-theme">Docker</span> and efficiently deploy applications using <span className="color-theme">Kubernetes</span>, leveraging <span className="color-theme">infrastructure-as-code (IaC)</span> tools like <span className="color-theme">Terraform</span>.
              Committed to <span className="color-theme">DevSecOps best practices</span>, I integrate <span className="color-theme">security-first approaches</span> into <span className="color-theme">CI/CD pipelines</span>, ensuring <span className="color-theme">secure deployments</span> and reinforcing <span className="color-theme">Kubernetes clusters</span> with <span className="color-theme">advanced cybersecurity protocols</span>. My goal is to deliver <span className="color-theme">high-performance, resilient, and secure software solutions</span> that drive innovation.
            </p>
            <div className="btn-bar">
              <a className="px-btn px-btn-theme" href="docs/Ife-resume.pdf" download onClick={() => handleClick("Download CV Clicked")}>
                Download CV
              </a>
            </div>
            <hr/>
            <h5>
              <span className="color-theme"> Creative Photographer / Story Teller</span>
            </h5>
            <p>
              I am a dedicated <span className="color-theme">photographer</span> and <span className="color-theme">visual storyteller</span>, driven by the art of capturing moments that weave compelling narratives. My creative journey merges <span className="color-theme">photography</span> and storytelling, ensuring that every image contributes to a larger, evocative visual tale.
              With a keen eye for detail and a deep appreciation for aesthetics, I specialise in <span className="color-theme">portrait photography</span>, expertly bringing out the essence and emotions of my subjects. My love for nature is reflected in my <span className="color-theme">landscape photography</span>, where I seek to encapsulate the grandeur and serenity of the natural world. Additionally, the dynamic energy of <span className="color-theme">street photography</span> inspires me to document the candid beauty of everyday life.
              Collaborating with other creative minds, including set <span className="color-theme">designers</span> and <span className="color-theme">stylists</span>, is where my passion truly thrives. Together, we transform concepts into captivating visual narratives that leave a lasting impact.
            </p>
            <a className="px-btn px-btn-theme btn-md" href="#photography">
              Photography
            </a>
            <hr/>
            <h5>
              Contact
            </h5>
            <div className="row about-list">
              {/* End .col */}
              <div className="col-md-6">
                <div className="media">
                  <label>Location</label>
                  <p>United Kingdom</p>
                </div>
                <div className="media">
                  <label>E-mail</label>
                  <p>info@floormind.com</p>
                </div>
                <div className="media">
                  <label>Instagram</label>
                  <p>@nomadcoder</p>
                </div>
                <div className="media">
                  <label>Twitter</label>
                  <p>@floormind</p>
                </div>
                <div className="media">
                  <label>Freelance</label>
                  <p>Available</p>
                </div>
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}
          </div>
          {/* End .about me */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default About;
