import React from "react";

const ServiceContent = [
  {
    icon: "ti-desktop",
    no: "01",
    service: "Software Engineering | Full-Stack Development",
    title: "Scalable Web & Application Development",
    desc: `With over 13 years of experience in software and web application development, I specialise in building high-performance, scalable, and secure solutions. 
           My expertise spans modern programming languages such as C#, Java, Python, and Go, along with JavaScript frameworks like React.  
           I have successfully designed and deployed enterprise-level microservices using .NET Core, leveraging cloud-native architectures to support organisations of all sizes, from startups to large enterprises.  
           My full-stack development experience includes API engineering, front-end development, and cloud-based solutions, ensuring seamless integration and maintainability across distributed systems.`,
  },
  {
    icon: "ti-loop",
    no: "02",
    service: "CI/CD Pipelines | GitOps Automation",
    title: "Seamless Continuous Integration & Deployment",
    desc: `Building scalable and reliable applications requires a well-structured CI/CD pipeline to automate integration, testing, and deployment. 
           I have extensive experience designing and optimising CI/CD workflows that enhance software reliability, speed, and security.  
           My expertise includes industry-leading tools such as TeamCity, Travis CI, GitLab CI/CD, and Azure Pipelines, alongside 
           GitOps solutions like ArgoCD, GitHub Actions, and FluxCD. By leveraging these technologies, I enable fully automated, version-controlled 
           deployments, infrastructure as code, and efficient rollback strategies, ensuring a secure and scalable software development lifecycle.`,
  },
  {
    icon: "ti-cloud",
    no: "03",
    service: "Cloud-Native Engineer | Platform Engineer",
    title: "Scalable & Secure Cloud Infrastructure",
    desc: `Most modern applications now run in cloud environments such as GCP, AWS, and Azure. 
           I have designed and deployed scalable and secure cloud infrastructures across all three major providers, 
           ensuring high availability and performance. 
           My experience includes developing and managing Kubernetes platforms on both GKE (Google Kubernetes Engine) 
           and EKS (Elastic Kubernetes Service), optimising container orchestration and automation. 
           I am also highly skilled in Infrastructure as Code (IaC) using Terraform and Pulumi, 
           enabling rapid deployment, reproducibility, and efficient infrastructure scaling.`,
  },
  {
    icon: "ti-shield",
    no: "04",
    service: "Cyber Security Engineer | DevSecOps Specialist",
    title: "Secure & Compliant Cloud Infrastructure",
    desc: `With extensive experience as a Cyber Security Engineer, I have designed and implemented security frameworks 
           to protect cloud-native applications and enterprise infrastructure. I specialise in securing cloud platforms 
           across GCP, AWS, and Azure, ensuring compliance with industry standards and best practices.  
           My expertise includes Kubernetes security, vulnerability management, threat detection, and policy enforcement. 
           I have deployed robust security measures using tools like Falco, Trivy, and Aqua Security to safeguard 
           containerised applications. Additionally, I integrate DevSecOps practices within CI/CD pipelines, leveraging 
           tools such as Vault for secret management, OPA/Gatekeeper for policy enforcement, and automated compliance checks.`,
  }
];

const ServiceTwo = () => {
  return (
    <>
      <div className="row gy-4">
        {ServiceContent.map((val, i) => (
          <div className="col-sm-6 col-lg-6" key={i}>
            <div className="feature-box-01">
              <div className="icon">
                <i className={val.icon}></i>
              </div>
              <h6>
                <span>{val.no}.</span> {val.service}
              </h6>
              <h5>{val.title}</h5>
              <p>{val.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ServiceTwo;
